<template>
  <!-- TableHead -->
  <div class="w-100 d-flex justify-content-center">
    <div class="w-100 table__container table-responsive">
      <table class="table table-striped table-nowrap mb-0">
        <thead class="sticky-header">
          <tr>
            <th v-for="(p, index) in thead" :key="index" :style="p.style">
              <a
                href="javascript:void(0)"
                @click="toggleSort(p.type)"
                style="text-decoration: none"
              >
                <span>{{ p.name }}</span>
                <span v-if="sort === p.type">
                  <i v-if="direction === 'ASC'" class="bi bi-caret-down-fill"></i>
                  <i v-else class="bi bi-caret-up-fill"></i>
                </span>
              </a>
            </th>
          </tr>
        </thead>
        <!-- TableBody -->
        <tbody>
          <tr v-if="tbody.length === 0">
            <td :colspan="thead.length" class="text-center table___radius">暫無資料</td>
          </tr>
          <tr v-for="(t, index) in tbody" :key="index">
            <td class="table__noWarp">{{ t.year }}</td>
            <td class="table__noWarp">{{ t.subject }}</td>
            <td style="max-width: 13rem" v-if="t.originalFileName" class="table__noWarp">
              <a :href="t.filePath">{{ t.originalFileName }}</a>
            </td>
            <template v-else-if="ShowNone('fileName')">
              <td style="max-width: 13rem">-</td>
            </template>
            <td style="max-width: 13rem" v-if="t.notifyOriginalFileName" class="table__noWarp">
              <a :href="t.notifyFilePath">{{ t.notifyOriginalFileName }}</a>
            </td>
            <template v-else-if="ShowNone('notifyFileName')">
              <td style="max-width: 13rem">-</td>
            </template>
            <td style="max-width: 13rem" v-if="t.manualOriginalFileName" class="table__noWarp">
              <a :href="t.manualFilePath">{{ t.manualOriginalFileName }}</a>
            </td>
            <template v-else-if="ShowNone('manualFileName')">
              <td style="max-width: 13rem">-</td>
            </template>
            <td style="max-width: 13rem" v-if="t.recordOriginalFileName" class="table__noWarp">
              <a :href="t.recordFilePath">{{ t.recordOriginalFileName }}</a>
            </td>
            <template v-else-if="ShowNone('recordFileName')">
              <td style="max-width: 13rem">-</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    thead: {
      type: Array,
      required: true,
    },
    tbody: {
      type: Array,
      required: true,
    },
    sort: {
      type: [String, Array],
    },
    direction: {
      type: [String, Array],
    },
  },
  emits: ["changeSort"],
  methods: {
    toggleSort(type) {
      if (type === this.sort) {
        this.$emit("changeSort", "direction", this.direction === "ASC" ? "DESC" : "ASC");
      } else {
        this.$emit("changeSort", "sort", type);
      }
    },
    ShowNone(name) {
      return this.tbody.some((t) => t[name]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/bootstrap";
.table {
  &__container {
    max-width: 80rem;
    margin: 0rem 5rem;
    border: none !important;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    @include media-breakpoint-down(md) {
      margin: 0rem 3rem;
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
  &__noWarp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  thead {
    background-color: var(--bs-primary);
    span {
      margin: 0.2rem 0;
      font-weight: 500;
    }
    span i {
      display: inline-flex;
      vertical-align: -0.125em;
      margin-left: 0.2rem;
      height: 0.5rem;
    }
  }
  th,
  td {
    font-size: 1rem;
    padding: 0.5rem 0.8rem;
    border: none;
  }
  th a {
    color: white;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 0.5rem;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 0.5rem;
  }
  &__radius {
    tr:last-child td:first-child {
      border-bottom-left-radius: none;
    }
    tr:first-child td:first-child {
      border-bottom-left-radius: 0.5rem;
    }
  }
}
</style>
