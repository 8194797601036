<template>
  <section class="pt-6 pt-lg-8">
    <div class="container">
      <div class="px-lg-8">
        <h2 class="content-title fs-1 text-center mb-8">{{ pageTitle }}</h2>
      </div>
    </div>
  </section>
  <TablePage
    :thead="table.thead"
    :tbody="table.tbody"
    :sort="queryParams.sort"
    :direction="queryParams.direction"
    @change-sort="replaceSort"
  ></TablePage>
  <Pagination style="margin-top: 3rem" :pages="pages" @changePage="changePage" />
</template>

<script>
import TablePage from "@/components/TablePage.vue";
import Pagination from "@/components/Pagination.vue";
import { apiQueryConference, apiQueryReport, apiQueryMeeting } from "@/api/shareholder";
export default {
  components: {
    TablePage,
    Pagination,
  },
  data() {
    return {
      pageTitle: "",
      table: {
        thead: [],
        theadOne: [
          { type: "year", name: "年度", style: "white-space: nowrap;" },
          { type: "title", name: "主旨" },
          { type: "file", name: "法說會資料" },
        ],
        theadTwo: [
          { type: "year", name: "年度", style: "white-space: nowrap;" },
          { type: "title", name: "主旨" },
          { type: "file", name: "年報" },
        ],
        theadThree: [
          {
            type: "year",
            name: "年度",
            style: "white-space: nowrap;",
          },
          { type: "title", name: "主旨" },
          { type: "notice", name: "通知" },
          { type: "manual", name: "手冊" },
          { type: "log", name: "紀錄" },
        ],
        tbody: [],
      },
      queryParams: {
        sort: "year",
        direction: "DESC",
      },
      pages: [],
    };
  },
  methods: {
    replaceSort(title, value) {
      this.queryParams[title] = value;
    },
    changePage(num) {
      this.queryData(num);
    },
    async getConference(num) {
      try {
        const res = await apiQueryConference({ page: num || 1, size: 10 });
        const {
          data: { data },
        } = res;
        this.table.tbody = data.content;
        this.pages = data.pageInfo;
      } catch (error) {
        this.$vErrorNotice();
      }
    },
    async getReport(num) {
      try {
        const res = await apiQueryReport({ page: num || 1, size: 10 });
        const {
          data: { data },
        } = res;
        this.table.tbody = data.content;
        this.pages = data.pageInfo;
      } catch (error) {
        this.$vErrorNotice();
      }
    },
    async getMeeting(num) {
      try {
        const res = await apiQueryMeeting({ page: num || 1, size: 10 });
        const {
          data: { data },
        } = res;
        this.table.tbody = data.content;
        this.pages = data.pageInfo;
      } catch (error) {
        this.$vErrorNotice();
      }
    },
    async queryData(num) {
      if (this.$route.name === "ShareholderInvestor") {
        this.pageTitle = "法人說明會";
        this.table.thead = this.table.theadOne;
        this.getConference(num);
      } else if (this.$route.name === "ShareholderReport") {
        this.pageTitle = "年報";
        this.table.thead = this.table.theadTwo;
        this.getReport(num);
      } else {
        this.pageTitle = "股東會";
        this.table.thead = this.table.theadThree;
        this.getMeeting(num);
      }
    },
  },
  created() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped>
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  box-shadow: gray;
  a {
    color: black;
  }
  a:hover {
    color: black;
  }
}
</style>
