import APIService from "../common/https";
const SHAREHOLDER = "shareholder";
const INVESTORCONF = "investorConf";
const ANNUALREPORT = "annualReport";
const SHAREHOLDERMEETING = "shareholderMeeting";
const SHAREHOLDERLIST = "shareholderList";

// 法人說明會
export const apiQueryConference = (params) => APIService.getSlug(SHAREHOLDER, INVESTORCONF, params);
// 年報
export const apiQueryReport = (params) => APIService.getSlug(SHAREHOLDER, ANNUALREPORT, params);
// 股東會
export const apiQueryMeeting = (params) =>
  APIService.getSlug(SHAREHOLDER, SHAREHOLDERMEETING, params);
// 主要股東名單
export const apiQueryList = (params) => APIService.getSlug(SHAREHOLDER, SHAREHOLDERLIST, params);
